/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;600&family=Poppins&family=Roboto+Condensed:wght@300&family=Roboto:wght@700&family=Sedgwick+Ave+Display&display=swap");
:root {
  /* --bs-font-sans-serif: "Inter", sans-serif; */
  --bs-font-sans-serif: "Poppins", sans-serif;
  --bs-body-font-family: "Poppins", sans-serif;
  /* --bs-body-font-family: "Inter", sans-serif; */
  --bs-body-font-size: 16px;
  --bs-body-font-weight: normal;
  --bs-body-line-height: 1.5;
  --bs-body-color: #f0ece5;
  --bs-body-bg: #161a30;
  --bs-primary: #62c1e5;
  --bs-secondary: #b6bbc4;
  --bs-secondary-rgb: #62c1e5;
  --border-radius: 6px;
  --shadow-rgba: rgba(0, 0, 0, 0.15);
  --bs-white: #fff;
  --bs-black: #000;
  --bg-section: #31304d;
  --bs-hover: #31304d;
}
/* Color palette */
/* https://colorhunt.co/palette/161a3031304db6bbc4f0ece5 */
body {
  color: var(--bs-body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bs-body-bg);
  font-family: var(--bs-body-font-family);
}
p {
  /* text-align: justify; */
  /* color: var(--bs-secondary); */
}
.substyle li {
  color: var(--bs-secondary);
}
a {
  cursor: pointer;
}
.link,
.link:hover,
.link:focus {
  color: var(--bs-primary);
}
h1,
h2,
h3,
h4,
h5,
h6,
h7,
.content-img-details h3,
.connected-account,
.navbar-nav .nav-link,
.web3nav h1 span,
.navbar-brand,
.small-heading {
  font-family: var(--bs-font-sans-serif);
  letter-spacing: 1px;
  margin: 0;
  font-weight: 800;
}
hr {
  margin: 3px;
}
a:hover {
  color: var(--bs-primary);
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: black;
  padding: 20px;
  border-radius: 50%;
}

strong {
  color: var(--bs-primary);
}
.small-heading {
}
.text-white {
  color: var(--bs-white);
}
.btn-header {
  padding: 15px 70px !important;
}
.text-bg-body {
  color: var(--bs-body-bg);
}
.h1-svg svg {
  fill: var(--bs-primary);
}
.bg-secondary-color {
  background-color: var(--bs-primary);
}
.danger {
  color: red !important;
}
.error-input {
  border-color: red !important;
}
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.header {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  background-image: url("../images/header-lg-min.webp");
  background-repeat: no-repeat;
}
.middle-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: auto;
}
.header figure {
  margin: 0;
}
.header img {
  width: 100%;
  height: auto;
}
.header .middle-content h1 {
  font-size: 100px;
  line-height: 1;
  margin-bottom: 10px;
  color: #fff;
}
.header .middle-content h1 small {
  display: block;
  font-size: 35px;
  font-weight: normal;
  padding-bottom: 10px;
}
.primary-color {
  color: var(--bs-primary);
}
.header .middle-content .datestyle {
  color: var(--bs-primary);
  font-size: 40px;
  font-family: "Comforter Brush" !important;
  display: block;
  margin-bottom: 0px;
  letter-spacing: normal;
}
.header .middle-content p {
  margin-bottom: 15px;
  margin-top: 10px;
  display: block;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 90%;
}
.header .middle-content .pay-card {
  width: auto;
  height: 40px;
  margin: auto;
  display: block;
  margin-bottom: 15px;
}
.header .middle-content h4 {
  font-size: 20px;
  margin-top: 150px;
}
.section {
  padding: 6.5rem 0;
}
.section .sm-head {
  font-size: 20px;
}
.section h2 {
  font-size: 70px;
  line-height: 1.1;
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
}
.section h4 {
  font-size: 30px;
  margin-bottom: 10px;
}
.section .s-card {
  position: relative;
}

.section .s-card h1 {
  color: var(--bs-primary);
}
.featured-box {
  box-sizing: border-box;
  position: relative;
}
.featured-box.style-3 {
  padding-left: 90px px;
  padding-top: 0px;
}
.featured-box.style-3 .featured-box-icon {
  width: 70px;
  height: 70px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.section .s-card h1 {
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 73px;
  font-weight: 900;
  opacity: 0.2;
  z-index: 1;
}
.bg-gray {
  background-color: var(--bg-section);
}
.pl-40 {
  padding-left: 40px;
}
.pt-75 {
  /* margin-top: 0.75rem; */
}
.active-port-link {
  background-color: var(--bs-primary) !important;
  color: var(--bs-black) !important;
}

.nav-pills .nav-item a:not(.active-port-link):hover {
  color: var(--bs-primary) !important;
}

.bg-promo {
  background-color: var(--bs-primary);
}

.bg-promo a {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}

.nav-pills li > a {
  color: var(--bs-body-color);
}
.section-right h2 {
  font-size: 50px;
  margin-bottom: 30px;
}
.section-right h3 {
  font-size: 25px;
  margin-bottom: 10px;
}
.section-right p {
  margin-bottom: 10px;
}
.section-right svg {
  width: 18px;
  height: 18px;
  color: var(--bs-primary);
}

footer {
  /* background-image: url(../images/bg-color.jpg); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
  padding: 50px 0;
  padding: 50px 0px 50px 0px;
  font-size: 13px;
}
footer .f-links {
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
}
footer .f-links a {
  font-size: 16px;
  margin: 0 12px;
  text-decoration: none;
  color: var(--bs-secondary);
  opacity: 0.8;
}
footer .f-links a svg {
  width: 24px;
  height: 24px;
}
footer .f-links a:hover {
  color: var(--bs-primary);
  opacity: 1;
}
.navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: all 1s ease;
}
.navbar-nav {
  margin-left: auto;
  padding-right: 20px;
}
.navbar-brand {
  padding: 15px 30px;
  color: #fff;
  font-size: 22px;
}
.navbar-brand:hover {
  color: #fff;
}
.navbar-brand img {
  width: auto;
  height: 185px;
  transition: all 0.5s ease;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
}
.navbar-nav .nav-link {
  color: #fff;
  margin: 0 12px;
  position: relative;
  padding: 10px 10px;
  font-size: 16px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.navbar-nav li.active > a {
  color: var(--bs-primary);
}
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: var(--bs-primary);
}
.navbar-nav .nav-link:active:after,
.navbar-nav .nav-link:focus:after,
.navbar-nav .nav-link:hover:after,
.navbar-nav .active .nav-link:after {
  width: 100%;
  -webkit-transition: all 0.1s ease;
  transition: all 0.5s ease;
}
.navbar-nav .nav-link:hover:before,
.navbar-nav.active .nav-link:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.navbar-nav .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  height: 2px;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  background-color: var(--bs-primary);
}
.navbar-nav .nav-link:before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-nav .nav-item:last-child .nav-link {
  border: solid 1px #fff;
  font-weight: normal;
  padding: 8px 20px;
  line-height: 1;
  margin-top: 6px;
}
.navbar-nav .nav-item:last-child .nav-link::after {
  display: none;
}
.navbar-toggler {
  background-color: var(--bs-primary);
  color: #fff;
  padding: 3px 6px;
  border-end-end-radius: var(--border-radius);
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}
.navbar-toggler svg {
  width: 28px;
  height: 28px;
  transition: all 1s ease;
}
.sc-down .navbar-brand img {
  opacity: 0;
  transition: all 1s ease;
}
.sc-up .navbar-brand {
  padding: 6px 30px;
  transition: all 1s ease;
}
.sc-up .navbar {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.5s ease;
  position: fixed;
}
.sc-up .navbar-brand img {
  height: 45px;
  top: 5px;
  opacity: 1;
  transition: all 0.01s ease;
}
.sc-up .navbar-nav .nav-link {
  font-size: 14px;
  transition: all 0.5s ease;
}
.sc-up .navbar-nav {
  padding-top: 0;
  transition: all 1s ease;
}
.btn,
.btn:hover,
.btn:focus,
.btn:active {
  border-radius: 6px;
  outline: none !important;
  box-shadow: none !important;
}
.btn-primary,
.btn {
  font-size: 16px;
  font-family: var(--bs-font-sans-serif);
  color: var(--bs-black);
  padding: 15px 30px;
  line-height: 1;
  background-color: var(--bs-primary);
  border: solid 1px var(--bs-secondary-rgb);
  font-weight: bold;
}
.btn-primary:hover,
.btn-primary:focus {
  border: solid 1px var(--bs-secondary-rgb);
  background-color: var(--bs-hover);
}
/* Active state - Same as normal state */
.btn-primary:active {
  background-color: var(--bs-primary);
}
.btn-secondary {
  background-color: var(--bs-secondary);
  color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--bs-primary);
  color: var(--bs-secondary);
}
.btn-link {
  color: var(--bs-secondary);
}
.btn-link:hover {
  color: var(--bs-primary);
}
.pt-10px {
  padding-top: 10px;
}
img {
  width: 100%;
  height: auto;
}
.align-items-center {
  align-items: center !important;
}
.form-label {
  margin-bottom: 7px;
  color: var(--bs-body-color);
  font-size: 14px;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.form-control,
.form-control:focus,
.form-select {
  outline: none !important;
  box-shadow: none !important;
  color: var(--bs-body-bg);
  background-color: #e2e2e2;
  border: 1px solid #e2e2e2;
  font-family: var(--bs-body-font-family);
  border-radius: 6px;
  resize: none;
  box-shadow: none;
  font-size: 14px;
}
.form-control:focus,
.form-select:focus {
  border: 1px solid var(--bs-primary) !important;
  box-shadow: none;
}
::placeholder {
  color: #7d7d7d !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #7d7d7d !important;
}
::-ms-input-placeholder {
  color: #7d7d7d !important;
}
.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--shadow-rgba);
}
.check-input .form-check-input {
  width: 1.2em;
  height: 1.2em;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.check-input .form-check-input:checked {
  background-color: #000;
  border-color: #000;
  border: 1px solid var(--bs-primary);
}
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  background-color: #fff;
  border: 1px solid #676767;
  box-shadow: none !important;
}
.form-check-label {
  padding-left: 5px;
  padding-top: 2px;
}
.event-card {
  border: solid 1px var(--bs-primary);
  border-radius: 12px;
  margin-bottom: 30px;
}
.event-card .heading {
  font-size: 16px;
  background-color: var(--bs-primary);
  padding: 10px 15px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.event-card .body {
  padding: 15px;
  color: #fff;
  font-size: 14px;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.connected-account {
  position: absolute;
  top: 90px;
  right: 20px;
  text-align: right;
  font-size: 13px;
  z-index: 5;
  letter-spacing: normal;
}
.spinner-border {
  width: 16px;
  height: 16px;
  border: 0.15em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  margin-left: 5px;
}
.btn-show,
.btn-show:focus,
.btn-show:hover {
  font-size: 14px;
  background-color: var(--bs-primary);
  color: #fff;
  margin-top: 20px;
}
/*  event-list  */
.event-list {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  background-color: #f8b9e4;
  color: var(--bs-secondary);
  border-radius: 8px;
  margin-bottom: 20px;
}
.event-list .event-date {
  font-size: 32px;
  font-family: var(--bs-font-sans-serif);
  color: #fff;
  line-height: 1;
  text-align: center;
  background-color: var(--bs-primary);
  min-width: 86px;
  padding: 13px 0;
  border-radius: 8px;
}
.event-list .event-date span {
  font-size: 11px;
  display: block;
  font-family: var(--bs-body-font-family);
  padding-top: 10px;
}
.event-list h3 {
  font-size: 20px;
  color: var(--bs-primary);
}
.event-list p {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 0;
}
.event-list svg {
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 5px;
  color: var(--bs-primary);
}
.eve-pr-20 {
  padding-right: 20px;
}
.event-list .col-auto {
  padding-right: 0;
}
/*  event-list end  */
.resume-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.resume-box li {
  position: relative;
  padding: 0 20px 0 60px;
  margin: 0 0 50px;
}
.resume-box .icon {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  border-radius: 50%;
  color: #fff;
  background-color: var(--bs-primary);
}
.resume-box .icon svg {
  width: 22px;
  height: 22px;
  margin-top: -2px;
}
.resume-box .time {
  font-size: 12px;
  padding: 1px 10px;
  display: inline-block;
  margin-bottom: 12px;
  border-radius: 20px;
  font-weight: normal;
  background-color: var(--bs-primary);
  margin-top: 8px;
  color: var(--bs-black);
}
.resume-box h5 {
  font-size: 18px;
  margin: 7px 0 20px;
}
.resume-box h5 span {
  opacity: 0.6;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  padding-left: 5px;
}
.resume-box p {
  margin: 0;
  font-size: 16px;
}
.resume-box li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  border-left: 1px solid var(--bs-primary);
}
.p-color {
  color: var(--bs-primary);
}
.st-img {
  margin-bottom: 10px;
  margin-top: 10px;
}

.st-img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
.substyle {
  padding: 0 !important;
  margin: 20px 0 20px -47px !important;
}
.substyle li {
  padding: 0;
  padding-left: 30px;
  font-size: 14px;
  margin-bottom: 15px;
}
.substyle li:after {
  display: none;
}
.substyle li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0px;
  height: 2px;
  width: 15px;
  background-color: var(--bs-primary);
}
.exp-progress {
  margin-top: 15px;
  margin-bottom: 15px;
}
.exp-progress h3 {
  margin-bottom: 10px;
  font-size: 20px;
}
.exp-progress h3 span {
  color: var(--bs-primary);
  font-size: 25px;
  padding-right: 15px;
}
.progress-bar {
  background-color: var(--bs-primary);
}
.progress {
  height: 8px;
}

@media (max-width: 1680px) {
}
@media (max-width: 1366px) {
  .section h2 {
    font-size: 60px;
  }
  .section-right h2 {
    font-size: 40px;
  }
  .section-right h3 {
    font-size: 22px;
  }
}
@media (max-width: 1280px) {
  .header .middle-content h1 {
    font-size: 55px;
  }
  .header .middle-content h1 small {
    font-size: 22px;
  }
  .header .middle-content p {
    font-size: 20px;
  }
  .navbar-nav .nav-link {
    margin: 0 8px;
  }
  .navbar-brand img {
    height: 160px;
  }
  .connected-account {
    top: 76px;
    right: 20px;
  }
  .section h2 {
    font-size: 55px;
  }
  .section-right h2 {
    font-size: 40px;
  }
}
@media (max-width: 1024px) {
  .header .middle-content h1 {
    font-size: 50px;
  }
  .section h2 {
    font-size: 50px;
  }
  .section .sm-head {
    font-size: 18px;
  }
  .section h4 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .section-right h2 {
    font-size: 35px;
  }
  .portfolio-overlay {
    display: block !important;
    /* position: absolute; */
    height: 60px !important;
    top: auto !important;
    border-radius: 0px !important;
  }

  .header {
    background-image: url("../images/header-md-min.webp");
    background-repeat: no-repeat;
  }
}
@media (max-width: 800px) {
  .header .middle-content h1 small {
    font-size: 18px;
  }
  .none-sc .navbar {
    background-color: rgba(0, 0, 0, 0.85);
  }
  .none-sc .navbar-brand img {
    height: 3.125rem;
  }
  body {
    font-size: 14px;
  }
  .header .middle-content h1 {
    font-size: 40px;
  }
  .section h2 {
    font-size: 40px;
    margin-bottom: 25px;
  }
  .header .middle-content p {
    font-size: 18px;
    letter-spacing: normal;
    width: 62%;
  }
  .section h4 {
    font-size: 24px;
  }
  .section-right h2 {
    font-size: 30px;
  }
  .section-right h3 {
    font-size: 20px;
  }
  .pt-md-set {
    padding-top: 30px;
  }
  .btn-primary,
  .btn {
    font-size: 14px;
    padding: 12px 19px;
  }
  .mb-md-set {
    margin-bottom: 30px;
  }
  .navbar-nav .nav-item:last-child .nav-link {
    border: solid 1px #fff;
    font-weight: normal;
    padding: 8px 20px;
    line-height: 1;
    width: fit-content;
    margin: auto;
    margin-bottom: 15px;
  }
  .navbar-toggler {
    color: var(--bs-secondary);
  }

  .img-testimonial {
    max-width: 50%;
  }
}
@media (max-width: 767px) {
  .header .middle-content h1 small {
    font-size: 15px;
  }
  .header .middle-content h1 {
    font-size: 36px;
  }
  .header .middle-content p {
    font-size: 16px;
    width: 349px;
  }
  .navbar-brand img {
    height: 100px;
  }
  .navbar {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
  .section {
    padding: 30px 0;
  }
  footer {
    padding: 40px 0px 40px 0px;
  }
  .section .s-card h1 {
    position: absolute;
    top: -20px;
  }
  .navbar-brand {
    padding: 8px 15px;
    color: #fff;
  }
  .resume-box h5 {
    font-size: 16px;
  }
  .resume-box p {
    font-size: 15px;
  }
  .exp-progress {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .exp-progress h3 span {
    font-size: 22px;
    padding-right: 10px;
  }
  .exp-progress h3 {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .middle-content {
    width: 100%;
    height: auto;
    /* padding-top: 125px; */
  }
  .navbar-toggler svg {
    width: 25px;
    height: 25px;
    fill: var(--bs-black);
  }
  .sc-up .navbar-brand img {
    height: 38px;
  }
  .section h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .section-right h3 {
    font-size: 18px;
  }
  .header {
    background-image: url("../images/header-xsm-min.webp");
    background-repeat: no-repeat;
  }
}
@media (max-width: 425px) {
  .header .middle-content h1 {
    font-size: 36px;
  }

  .pl-40 {
    padding-left: 33px;
  }
  .header .middle-content p {
    margin-bottom: 0;
    margin-top: 0;
  }
  .section h2 {
    font-size: 28px;
  }
  .section h4 {
    font-size: 22px;
  }
  .section .s-card h1 {
    top: -10px;
    font-size: 55px;
  }
  .section .sm-head {
    font-size: 16px;
  }
  .section-right h3 {
    font-size: 16px;
  }
  .pt-md-set {
    padding-top: 30px;
  }
  .middle-content {
    /* padding-top: 85px; */
  }
}
@media (max-width: 375px) {
  .header .middle-content h1 {
    font-size: 34px;
  }
  .header .middle-content p {
    width: 100%;
  }
  footer .f-links a svg {
    width: 22px;
    height: 22px;
  }
  footer {
    padding: 20px 0;
  }
  footer .f-links {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .navbar-brand img {
    height: 70px;
  }
}
@media (max-width: 320px) {
  .header .middle-content h1 {
    font-size: 30px;
  }

  .pl-40 {
    padding-left: 29px;
  }
  .middle-content {
    padding-top: 80px;
  }
  .header .middle-content p {
    font-size: 13px;
  }
  .section h2 {
    font-size: 24px;
  }
  .section h4 {
    font-size: 20px;
  }
  .navbar-brand img {
    height: 60px;
  }
}
