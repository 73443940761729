.client {
  width: 100%;
  height: 100%;
  background: #7f00ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e100ff,
    #7f00ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #e100ff,
    #7f00ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.carousel-icon i {
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.3);
}

.carousel-item i {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.3);
}

.t-card {
  padding: 1.8125rem 1.125rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1.25rem;
  color: #fff;
  height: auto;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 1.5625rem solid transparent;
  border-right: 1.5625rem solid transparent;
  border-top: 1.25rem solid rgba(0, 0, 0, 0.5);
  margin-left: 20px;
}
